<template>
  <item-wrapper id="shipping">
    <item-header :title="t('shipping.title')" />
    <main>
      <p>{{ t("shipping.content") }}</p>
    </main>
  </item-wrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import ItemWrapper from '@/components/footerlink/ItemWrapper.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ShippingInformation',
  components: {
    ItemHeader,
    ItemWrapper,
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixin.scss";
@import "@/assets/styles/variables.scss";

main {
  @include flex-center();
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 61px;
  p {
    font-size: 18px;
    font-weight: 400;
    color: #545454;
    line-height: 35px;
  }
}
</style>
